<template>
  <b-overlay
    :show="overlayChartUmur"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card 
      no-body
      :aria-hidden="overlayChartUmur ? true : null"
    >
      <b-card-header>
        <b-card-title>
          <h4 class="mb-0">
            Age
          </h4>
        </b-card-title>
      </b-card-header>

      <!-- chart info -->
      <vue-apex-charts
        ref="chartUmur"
        type="donut"
        height="300"
        class="my-1"
        :options="umurData.chartOptions"
        :series="umurData.series"
      />
      <div
        v-for="(data, index) in umurData.data"
        :key="index"
        class="d-flex justify-content-between mx-2 mb-2"
        :class="index === umurData.data.length - 1 ? 'mb-0':'mb-1'"
      >
        <div class="series-info d-flex align-items-center">
          <feather-icon
            icon="UserIcon"
            size="16"
            :style="{ color: data.iconColor }"
          />
          <span class="font-weight-bolder ml-75 mr-25">{{ data.name }}</span>
        </div>
        <div>
          <span>{{ data.usage }}%</span>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
  import {
    BRow, BCol,
    BOverlay, BCard, BCardText, BCardHeader, BCardTitle, BCardBody
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      BRow, BCol,
      BOverlay, BCard, BCardText, BCardHeader, BCardTitle, BCardBody,
      VueApexCharts
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        umurData: {
          data: [],
          series: [],
          chartOptions: {
            chart: {
              toolbar: {
                show: false,
              },
            },
            labels: [],
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              enabled: true,
              custom: function({ series, seriesIndex, w }) {
                return `<span style="background: ${w.config.colors[seriesIndex]}; padding: 10px; font-size: 12px">
                  ${w.config.labels[seriesIndex]}: <b>${series[seriesIndex]}%<b/>
                </span>`
              }
            },
            legend: { show: false },
            comparedResult: [2, -3, 8],
            stroke: { width: 0 },
            colors: [],
          },
        },
        overlayChartUmur: true,
      }
    },
    computed: {
      data() {
        return this.$store.state.dashboard.age
      }
    },
    watch: {
      data: function(newVal, oldVal) {
        if(newVal) {
          this.chartMemberUmur()
        }
      }
    },
    methods: {
      chartMemberUmur() {
        let chartUmur = this.umurData
        let chartOpt = chartUmur.chartOptions

        const chartColor = ["#97CE68", "#775DD0", "#00B1F2", "#4CAF50", "#D7263D", "#C5D86D", "#A5978B"]
        chartOpt.colors = chartColor

        let chartSeries = this.data.chart
        // let chartSeries = [0, 51, 158, 67, 8, 0, 0]

        let i = 0
        let chartData = []
        this.data.data.forEach(item => {
          let item_chart = {
            iconColor: chartColor[i],
            name: item.name,
            usage: item.value,
          }
          
          chartData.push(item_chart)
          i++
        })

        chartUmur.data = chartData
        chartUmur.series = this.data.chart
        
        this.$refs.chartUmur.updateSeries([{
          data: chartSeries,
        }], false, true)

        this.$refs.chartUmur.updateOptions({ 
          colors: chartColor,
          labels: this.data.data.map(item => item.name)
        })
        
        this.overlayChartUmur = false
      },
    }
  }
</script>