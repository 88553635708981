<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col cols="12" sm="6" md="4">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar 
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="DollarSignIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                $9745
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Revenue
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col cols="12" sm="6" md="4" class="mt-2 mt-sm-0 mt-md-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar 
                size="48"
                variant="light-danger"
              >
                <feather-icon
                  size="24"
                  icon="BoxIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                1.423k
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Products
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col cols="12" sm="6" md="4" class="mt-2 mt-sm-2 mt-md-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar 
                size="48"
                variant="light-warning"
              >
                <feather-icon
                  size="24"
                  icon="TrendingUpIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                230k
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Sales
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
  import {
    BRow, BCol,
    BCard, BCardText, BCardHeader, BCardTitle, BCardBody,
    BMedia, BMediaAside, BMediaBody,
    BOverlay, BAvatar
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      BRow, BCol,
      BCard, BCardText, BCardHeader, BCardTitle, BCardBody,
      BMedia, BMediaAside, BMediaBody,
      BOverlay, BAvatar,
      VueApexCharts
    },
    directives: {
      Ripple,
    },
    data() {
      return {

      }
    },
    created() {

    },
    methods: {
      
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>