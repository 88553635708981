<template>
  <b-overlay
    :show="overlayChartProviderType"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card 
      no-body
      :aria-hidden="overlayChartProviderType ? true : null">
      <b-card-header>
        <b-card-title class="mb-50">
          <h4 class="mb-0">Provider and Smartphone</h4>
        </b-card-title>
      </b-card-header>

      <b-row class="mb-2">
        <b-col cols="12" md="6">
          <vue-apex-charts
            ref="chartProvider"
            type="donut"
            height="350"
            :options="memberProvider.chartOptions"
            :series="memberProvider.series"
          />
        </b-col>
        <b-col cols="12" md="6">
          <vue-apex-charts
            ref="chartPhoneType"
            type="donut"
            height="350"
            :options="memberPhoneType.chartOptions"
            :series="memberPhoneType.series"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
  import {
    BRow, BCol,
    BOverlay, BCard, BCardText, BCardHeader, BCardTitle
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      BRow, BCol,
      BOverlay, BCard, BCardText, BCardHeader, BCardTitle,
      VueApexCharts
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        memberProvider: {
          series: [],
          chartOptions: {
            legend: {
              show: true,
              position: 'bottom',
              fontSize: '14px',
              fontFamily: 'Montserrat',
            },
            colors: [],
            dataLabels: {
              enabled: true,
              formatter(val) {
                // eslint-disable-next-line radix
                return `${parseInt(val)}%`
              },
            },
            tooltip: {
              enabled: true,
              custom: function({ series, seriesIndex, w }) {
                return `<span style="background: ${w.config.colors[seriesIndex]}; padding: 10px; font-size: 12px">
                  ${w.config.labels[seriesIndex]}: <b>${series[seriesIndex]}% <b/>
                </span>`
              }
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '2rem',
                      fontFamily: 'Montserrat',
                    },
                    value: {
                      fontSize: '1rem',
                      fontFamily: 'Montserrat',
                      formatter(val) {
                        // eslint-disable-next-line radix
                        return `${parseInt(val)}%`
                      },
                    },
                  },
                },
              },
            },
            labels: []
          },
        },

        memberPhoneType: {
          series: [],
          chartOptions: {
            legend: {
              show: true,
              position: 'bottom',
              fontSize: '14px',
              fontFamily: 'Montserrat',
            },
            colors: [],
            dataLabels: {
              enabled: true,
              formatter(val) {
                // eslint-disable-next-line radix
                return `${parseInt(val)}%`
              },
            },
            tooltip: {
              enabled: true,
              custom: function({ series, seriesIndex, w }) {
                return `<span style="background: ${w.config.colors[seriesIndex]}; padding: 10px; font-size: 12px">
                  ${w.config.labels[seriesIndex]}: <b>${series[seriesIndex]}% <b/>
                </span>`
              }
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '2rem',
                      fontFamily: 'Montserrat',
                    },
                    value: {
                      fontSize: '1rem',
                      fontFamily: 'Montserrat',
                      formatter(val) {
                        // eslint-disable-next-line radix
                        return `${parseInt(val)}%`
                      },
                    },
                  },
                },
              },
            },
            labels: []
          },
        },
        overlayChartProviderType: true
      }
    },
    computed: {
      provider() {
        return this.$store.state.dashboard.provider
      },
      smartphone() {
        return this.$store.state.dashboard.smartphone
      }
    },
    watch: {
      provider: function(newVal, oldVal) {
        if(newVal) {
          this.chartPhoneType()
        }
      },
      smartphone: function(newVal, oldVal) {
        if(newVal) {
          this.chartProvider()
        }
      }
    },
    methods: {
      chartProvider() {
        let chartProvider = this.memberProvider
        let chartOpt = chartProvider.chartOptions

        const chartColor = ["#546E7A", "#5653FE", "#F9C80E", "#EA3546", "#008FFB"]
        chartOpt.label = this.provider.data.map(item => item.name)

        let chartSeries = this.provider.chart

        chartProvider.series = chartSeries

        this.$refs.chartProvider.updateSeries([{
          data: chartSeries,
        }], false, true)

        this.$refs.chartProvider.updateOptions({ 
          colors: chartColor,
          labels: this.provider.data.map(item => item.name)
        })
      },
      chartPhoneType() {
        let chartPhoneType = this.memberPhoneType
        let chartOpt = chartPhoneType.chartOptions

        const chartColor = ["#546E7A", "#3F51B5", "#1B2631", "#03A9F4", "#F9C80E"]
        chartOpt.label = this.smartphone.data.map(item => item.name)

        let chartSeries = this.smartphone.chart

        chartPhoneType.series = chartSeries

        this.$refs.chartPhoneType.updateSeries([{
          data: chartSeries,
        }], false, true)

        this.$refs.chartPhoneType.updateOptions({ 
          colors: chartColor,
          labels: this.smartphone.data.map(item => item.name)
        })

        this.overlayChartProviderType = false
      }
    }
  }
</script>