<template>
  <b-overlay
    :show="overlayFilter"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card 
      no-body
      :aria-hidden="overlayFilter ? true : null"
    >
      <b-card-header>
        <b-card-title>
          <h4 class="mb-0">
            Filter
          </h4>
        </b-card-title>
      </b-card-header>

      <b-row class="mx-2 mb-2">
        <b-col md="6">
          <b-form-group label="Corporate" label-for="corporate">
            <b-form-select 
              id="corporate"
              @change="setCorporateOptions()"
              v-model="dataFilter.corporate"
              :options="corporateOptions"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Start Date" label-for="start_date">
            <flat-pickr
              v-model="dataFilter.start_date"
              @on-change="setCorporateOptions()"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', maxDate: dataFilter.end_date ? dataFilter.end_date : date }"
              />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="End Date" label-for="end_date">
            <flat-pickr
              v-model="dataFilter.end_date"
              @on-change="setCorporateOptions()"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', maxDate: date }"
              />
          </b-form-group>
        </b-col>
        <b-col offset="10">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="resetCorporateOptions()"
          >
            Reset Filter
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import {
    BRow, BCol, BFormGroup, BFormSelect, BButton,
    BOverlay, BCard, BCardText, BCardHeader, BCardTitle, BCardBody
  } from 'bootstrap-vue'
  import flatPickr from 'vue-flatpickr-component'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      BRow, BCol, BFormGroup, BFormSelect, BButton,
      BOverlay, BCard, BCardText, BCardHeader, BCardTitle, BCardBody,
      flatPickr
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        dataFilter: {
          start_date: null,
          end_date: null,
          corporate: null,
        },
        date: new Date(),
        overlayFilter: true,
        corporateOptions: []
      }
    },
    computed: {
      data() {
        return this.$store.state.dashboard.corporate
      }
    },
    watch: {
      data: function(newVal, oldVal) {
        if(newVal) {
          this.corporateOption()
        }
      }
    },
    mounted () {
      setTimeout(() => {
        this.overlayFilter = false
      }, 750)
    },
    methods: {
      corporateOption() {
        this.data.forEach(item => {
          let state = {
            value: item.id,
            text: item.corporate_name
          }

          this.corporateOptions.push(state)
        })
      },
      setCorporateOptions() {
        this.$store.dispatch('dashboard/setCorporateOptions', this.dataFilter)
          .then(res => {})
          .catch(error => {})
      },
      resetCorporateOptions() {
        this.dataFilter = {
          start_date: null,
          end_date: null,
          corporate: null,
        }
        this.$store.dispatch('dashboard/setCorporateOptions', this.dataFilter)
          .then(res => {})
          .catch(error => {})
      }
    },
  }
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>