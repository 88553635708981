<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" class="mb-2" v-if="active_role === 'superadmin'">
        <member-filter/>
      </b-col>
      <b-col cols="12" md="4"> 
        <b-row>
          <b-col cols="12">
            <div v-if="active_role !== 'superadmin'">
              <member-onboarding/>
            </div>
          </b-col>
          <b-col cols="12">
            <member-bussines-category />
          </b-col>
          <b-col cols="12">
            <member-ages />
          </b-col>
          <!-- <b-col cols="12">
            <member-digital-literacy />
          </b-col> -->
          <b-col cols="12">
            <member-socioeconomic />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="8">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="6" md="3">
                <member-men />
              </b-col>
              <b-col cols="6" md="3">
                <member-women />
              </b-col>
              <b-col cols="12" md="6">
                <member-text-savvy />
              </b-col>
            </b-row>
          </b-col>
          <!-- <b-col cols="12">
            <member-statistics />
          </b-col> -->
          <!-- <b-col cols="12">
            <member-report />
          </b-col> -->
          <b-col cols="12">
            <member-occupation />
          </b-col>
          <b-col cols="12">
            <member-education />
          </b-col>
          <b-col cols="12">
            <member-provider-type />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { 
  BCard, BCardText, BCardHeader, BCardTitle, BCardBody,
  BLink, BRow, BCol, BOverlay,
  BMedia, BMediaAside, BAvatar, BMediaBody
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'

import MemberOnboarding from './dashboard/MemberOnboarding.vue'
import MemberBussinesCategory from './dashboard/MemberBussinesCategory.vue'
import MemberAges from './dashboard/MemberAges.vue'
import MemberDigitalLiteracy from './dashboard/MemberDigitalLiteracy.vue'
import MemberSocioeconomic from './dashboard/MemberSocioeconomic.vue'
import MemberMen from './dashboard/MemberMen.vue'
import MemberWomen from './dashboard/MemberWomen.vue'
import MemberTextSavvy from './dashboard/MemberTextSavvy.vue'
import MemberStatistics from './dashboard/MemberStatistics.vue'
import MemberReport from './dashboard/MemberReport.vue'
import MemberOccupation from './dashboard/MemberOccupation.vue'
import MemberEducation from './dashboard/MemberEducation.vue'
import MemberProviderType from './dashboard/MemberProviderType'
import MemberFilter from './dashboard/MemberFilter'

export default {
  components: {
    BCard, BCardText, BCardHeader, BCardTitle, BCardBody,
    BLink, BRow, BCol, BOverlay,
    BMedia, BMediaAside, BAvatar, BMediaBody,
    VueApexCharts, flatPickr,

    MemberOnboarding,
    MemberBussinesCategory,
    MemberAges,
    MemberDigitalLiteracy,
    MemberSocioeconomic,
    MemberMen,
    MemberWomen,
    MemberTextSavvy,
    MemberStatistics,
    MemberReport,
    MemberOccupation,
    MemberEducation,
    MemberProviderType,
    MemberFilter
  },
  data() {
    return {
      active_role: null,
      data: {
        start_date: null,
      }
    }
  },
  created() {
    this.active_role = localStorage.getItem('role')
    this.checkAuth()
    this.resetCorporateOptions()
  },
  methods: {
    resetCorporateOptions() {
      this.dataFilter = {
        start_date: null,
        end_date: null,
        corporate: null,
      }
      this.$store.dispatch('dashboard/setCorporateOptions', this.dataFilter)
        .then(res => {})
        .catch(error => {})
      setTimeout(() => {
        this.getDataDashboard()
      }, 500)
    },
    getDataDashboard() {
      const dataFilter = {
        start_date: null,
        end_date: null,
        corporate: null,
      }
      this.$store.dispatch('dashboard/loadDashboard', dataFilter)
        .then(res => {})
        .catch(error => {})
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>